import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  CardContent,
  CardActionArea,
  Collapse,
} from '@material-ui/core';

// import { ArrowDownIcon } from '../Icons';
import Card from './Card';
import { Text2, Heading1, Note } from '../../typography';
import PerformancePill from '../PerformancePill';
import ClickIndicator from './ClickIndicator';
import { isNullOrUndefined } from '../../util/object';
import { injectIntl } from 'react-intl';
import KPiGraphTabs from './KPiGraphTabs';
import { TrendsLineIcon } from '../Icons';
import { GTAG_EVENT } from '../../gtag';
import { withTraslation } from '../../data/LanguageProvider';

const paddingTopBottom = 1.75;
const paddingLeftRight = 1.5; // as theme.spacing units
const useStyles = props => {
  return makeStyles(theme => ({
    cardRoot: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    content: {
      minHeight: 116,
      boxSizing: 'border-box',
      display: 'inline-flex',
      flexDirection: 'column',
      padding: theme.spacing(paddingTopBottom, paddingLeftRight),
      width: '100%',
    },
    contentNumber: {
      // minHeight: 116,
      height: 84,
      justifyContent: 'space-between',
      // height: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    valueAndPills: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    pills: {
      display: 'flex',
      flexDirection: 'column',
      '& > :last-child': {
        marginTop: 2,
      },
    },
    pill: {
      borderRadius: 12,
      height: 24,
      marginLeft: 'auto',
      minWidth: 'unset',
    },
    label: {
      marginBottom: 6,
      display: 'flex',
      flexDirection: 'row-start',
    },
    clickIndicator: {
      float: 'right',
      top: -5,
      position: 'relative',
      marginLeft: 2,
      cursor: 'pointer',
    },
    chartIcon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(0.5),
      cursor: 'pointer',
    },
    arrowIcon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(-0.25),
      position: 'relative',
      cursor: 'pointer',
      float: 'right',
    },
    chart: {
      height: 320,
      width: '100%',
      paddingBottom: theme.spacing(1.5),
    },
    actionArea: {
      width: '30px',
      borderRadius: '100%',
    },
  }));
};
// const chartData = {
//   labels: [
//     'Monday',
//     'Tuesday',
//     'Wednesday',
//     'Thursday',
//     'Friday',
//     'Saturday',
//     'Sunday',
//   ],
//   series: [
//     [12, 9, 7, 8, 5, 7, 4],
//     [2, 1, 3.5, 7, 3, 8, 2],
//   ],
// };
const PromotedMetricCard = ({
  onClick,
  metric,
  useLLY,
  style = {},
  color,
  spacing,
  moreDetails = false,
  intl,
  useUsdAmount,
}) => {
  const classes = useStyles(style)();
  const [expanded, setExpanded] = React.useState(false);

  const {
    id,
    label,
    formattedValue,
    formattedLLYValue,
    dpy,
    dpp,
    value,
    value_lly,
    formattedTargetValue,
    formattedTrends,
    format,
  } = metric;

  const pillLabel = useLLY ? 'vsPPY' : 'vsPY';
  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (expanded) {
      GTAG_EVENT({ view: 'trends_view', category: 'Trendlines', label: label });
    }
  };
  const trendsLength = formattedTrends
    ? Object.keys(formattedTrends).length
    : 0;
  return (
    <Card color={color} spacing={spacing} className={classes.cardRoot}>
      <CardContent className={classes.content}>
        <div className={classes.contentNumber}>
          <div className={classes.label}>
            <Text2 style={{ width: '100%' }}>
              {moreDetails && trendsLength > 0 && (
                <>
                  <TrendsLineIcon
                    fontSize="large"
                    className={classes.chartIcon}
                    onClick={handleExpandClick}
                  />
                </>
              )}
              {withTraslation(id)}
            </Text2>
            {onClick && (
              <CardActionArea onClick={onClick} className={classes.actionArea}>
                <ClickIndicator
                  className={classes.clickIndicator}
                  onClick={onClick}
                />
              </CardActionArea>
            )}
          </div>
          <div className={classes.valueAndPills}>
            <div>
              {(!isNullOrUndefined(value) || !isNullOrUndefined(value_lly)) && (
                <Heading1 caps={false} style={{ fontSize: '3rem' }}>
                  {useLLY && formattedLLYValue
                    ? formattedLLYValue
                    : formattedValue}
                </Heading1>
              )}

              {!isNullOrUndefined(formattedTargetValue) && (
                <Note
                  gray
                  caps={false}
                  style={{ display: 'block', marginTop: 4 }}
                >
                  of {formattedTargetValue} {intl.formatMessage({ id: 'plan' })}
                </Note>
              )}
            </div>
            <div className={classes.pills}>
              {dpp && (
                <PerformancePill
                  classes={{ root: classes.pill }}
                  delta={dpp}
                  label={intl.formatMessage({ id: 'vs_plan' })}
                  showArrow={false}
                />
              )}
              {dpy && (
                <PerformancePill
                  classes={{ root: classes.pill }}
                  delta={dpy}
                  label={intl.formatMessage({ id: pillLabel })}
                  showArrow={false}
                />
              )}
            </div>
          </div>
        </div>
        <Collapse
          in={trendsLength > 0 ? expanded : false}
          timeout="auto"
          unmountOnExit
        >
          <KPiGraphTabs
            data={formattedTrends}
            dots={false}
            dataKeyA="value"
            dataKeyB="value_py"
            strokeA="#8884d8"
            strokeB="#D0D0D0"
            useLLY={useLLY}
            format={format}
            useUsdAmount={useUsdAmount}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
};

PromotedMetricCard.propTypes = {
  onClick: PropTypes.func,
  metric: PropTypes.object.isRequired,
};

export default injectIntl(PromotedMetricCard);
